<template>
    <MoneyRequest class="money-request"/>
</template>

<script>
    import MoneyRequest from '@/components/MoneyRequest.vue'

    export default {
        name: 'money-request',
        components: {
            MoneyRequest
        },
        mounted: function() {
            this.$ebus.$emit("title:set", this.$t("menu.moneyRequest"))
        },
        destroyed: function() {
            this.$ebus.$emit("title:reset")
        }
    }
</script>

<style scoped lang="scss">   
    @import 'src/scss/main';

    .money-request {
        margin-top: $container-top-margin;
    }
</style>