<template>
    <div>
        <b-modal class="confirm_money_request" centered
            v-model="confirmShow"
            :ok-title="$t('moneyRequest.confirm.yes') | uppercase"
            :cancel-title="$t('moneyRequest.confirm.no') | uppercase"
            cancel-variant="light"
            ok-variant="success"
            :hide-header="true"
            @ok="send">
            <p v-html="$t('moneyRequest.confirm.text')"></p>                
        </b-modal>

        <b-form>
            <b-row class="datetime section">
                <b-col>
                    <b-row class="label">
                        <b-col cols="1"><b-img center src="img/icons/event_24px.png"/></b-col>
                        <b-col>{{ $t("moneyRequest.datetime") }}</b-col>
                    </b-row>
                    <b-row class="value" :class="isMoneyRequestSent ? 'disabled' : ''">
                        <b-col offset="1">
                            <div v-if="isMoneyRequestSent && datetime">
                                <span class="mr-5">{{ datetime | moment("DD. MM. YYYY") }} {{ $t("moneyRequest." + $moment(datetime).format("a")) }}</span>
                            </div>
                            <b-form-select v-else v-model="datetime" :options="datetimeOptions" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row> 

            <b-row class="amount">
                <b-col>
                    <b-row class="label">
                        <b-col cols="1"><b-img center src="img/icons/euro_symbol_24px.png"/></b-col>
                        <b-col>{{ $t("moneyRequest.amount") }}</b-col>
                    </b-row>
                    <b-row class="value" :class="isMoneyRequestSent ? 'disabled' : ''">
                        <b-col offset="1">
                            <b-form-group :invalid-feedback="invalidAmountFeedback" :state="isAmountValid">
                                <b-input-group>
                                    <b-input-group-text slot="append">{{ $t("moneyRequest.currency") }}</b-input-group-text>
                                    <b-form-input type="number" v-model="amount" required :state="isAmountValid" :disabled="isMoneyRequestSent" class="bottom-bordered"/>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row v-if="!isMoneyRequestSent">
                <b-col>
                    <ButtonRow>
                        <button @click.prevent="confirmShow = true" class="btn btn-success" type="submit" :disabled="!isAmountValid">{{ $t("button.send") | uppercase}}</button>
                    </ButtonRow>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
    /* eslint-disable */
    import {CONFIG} from '../config.js'
    import ButtonRow from '@/components/ButtonRow.vue'

    export default {
        data: function() {
            return {
                confirmShow: false,
                datetime: null,
                amount: null,
                amountChanged: false,
                isMoneyRequestSent: false,
                datetimeOptions: []
            }
        },
        watch: {
            amount: function(v) {
                this.amountChanged = (v !== null && !this.isMoneyRequestSent);
            },
            isMoneyRequestSent: function(v) {
                if (v) {
                    this.amountChanged = false;
                }
            }
        },
        components: { ButtonRow },
        computed: {
            isAmountValid: function() {
                if (!this.amountChanged) {
                    return null;
                }

                return this.invalidAmountFeedback === '';
            },
            invalidAmountFeedback: function() {
                if (!this.amountChanged) {
                    return '';
                }

                if (!this.amount) {
                    return this.$t('moneyRequest.error.empty')
                } else if (this.amount < 0) {
                    return this.$t(this.$t('moneyRequest.error.negativeAmount'))
                }

                return '';
            }
        },
        mounted: function() {
            var self = this;

            this.$protected("get", CONFIG.api.endpoints.moneyRequest,
                function (response) {
                    // check if the money request is already sent
                    if (response.data.length) {
                        self.amount = response.data[0].amount
                        self.datetime = self.$moment(response.data[0].prepayment_time, self.$moment.ISO_8601)
                        self.isMoneyRequestSent = true         
                    } else {
                        // otherwise get list of datetimes
                        self.$protected("get", CONFIG.api.endpoints.moneyRequestDatetimes,
                            function(response) {
                                if (response.data && response.data.length) {
                                    self.datetimeOptions = response.data.map(dt => {
                                        var moment = self.$moment(dt.prepayment_timestamp, self.$moment.ISO_8601)
                                        return {
                                            value: moment.format('YYYY-MM-DD'),
                                            text: `${self.getLocalizedWeekDay( moment.isoWeekday())} ${moment.format('DD.MM.YYYY')}`
                                        }
                                    })
                                }
                            },
                            {
                                headers: {
                                    'Accept-Profile': 'work_agency'
                                }
                            })
                    }
                },
                {
                    headers: {
                        'Accept-Profile': 'work_agency'
                    }
                });
        },
        methods: {
            send: function() {
                var self = this;

                return this.$protected("post", CONFIG.api.endpoints.moneyRequest,
                    function () {
                        self.$ebus.$emit('msg:new', {type: "message-default", data: {text: self.$t("moneyRequest.success"), variant: "success"}});
                        self.isMoneyRequestSent = true
                    },
                    {
                        data: {
                            amount: this.amount,
                            prepayment_time: self.$moment(this.datetime).format("YYYY-MM-DD HH:mm:ss")
                        },
                        headers: {
                            'Content-Profile': 'work_agency'
                        }
                    });
            },
            getLocalizedWeekDay(dayIndex) {
                switch (dayIndex) {
                    case 1:
                        return this.$t("weekDays.monday")
                    case 2:
                        return this.$t("weekDays.tuesday")
                    case 3:
                        return this.$t("weekDays.wednesday")
                    case 4:
                        return this.$t("weekDays.thursday")
                    case 5:
                        return this.$t("weekDays.friday")
                    case 6:
                        return this.$t("weekDays.saturday")
                    case 7:
                        return this.$t("weekDays.sunday")
                }
            }
        },
        filters: {
            uppercase: function(v) {
                return v.toUpperCase();
            }
        }
    }
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
    }

    .label {
        font-weight: $font-bold;
    }

    .amount .value {
        input {
            text-align: right;            
        }

        .form-group {
            width: 200px;
        }
    }

    .datetime .value select {
        width: 200px;
    }

    .value.disabled *, input:disabled {
        color: $light !important;
        background-color: transparent !important;
        border-color: $light !important;
    }
    
</style>